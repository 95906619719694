@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;600;700&display=swap');

// font family
$primary-font-family: 'Roboto', sans-serif;
$title-font-family: 'Cairo', sans-serif;

// font size:
$font-size-12: 1.2rem;
$font-size-14: 1.4rem;
$font-size-16: 1.6rem;
$font-size-18: 1.8rem;
$font-size-20: 2.0rem;
$font-size-24: 2.4rem;
$font-size-26: 2.6rem;
$font-size-32: 3.2rem;
$font-size-34: 3.4rem;
$font-size-40: 4.0rem;
$font-size-48: 4.8rem;

// base color:
$primary-color: var(--primaryColor,#0090d1);
$secondary-color: var(--secondaryColor,#152935);
$white-color: var(--foregroundMain,#ffffff);
$alert-color: #d82829;
$gray-color: #c9d9e8;
$vertical-primary-gradient: linear-gradient(0deg, rgba(0,144,209,1) 27%, rgba(0,195,234,1) 79%);

// basic colors:
$background-main: var(--backgroundMain,#e4eaf0);

$title-color: var(--title,#005075);
$subtitle-color: var(--description,#9da6ad);
$text-color: var(--textColor,#152935);
$link-color: var(--wallet,#00c3ea);

$secondary-button-foreground: var(--secondaryButtonForeground,#0090d1);
$secondary-button-background: var(--secondaryButtonBackground,#f2f5f8);

$input-border-color: var(--inputBorderColor,#c9d9e8);
$input-background-color: $secondary-button-background;
$input-placeholder-color: #c1c1c4;
$input-text-color: #152935;

$checkbox-mark-color: $white-color;
$checkbox-background-color: $vertical-primary-gradient;

$radio-border-color: $vertical-primary-gradient;
$radio-dot-color: $vertical-primary-gradient;

$accordion-background-color: var(--accordionBackground,#f2f5f8);
$accordion-foreground-color: var(--accordionForeground,#005075);

$tab-background-color: $accordion-background-color;
$tab-border-color: #c9d9e8;

$button-outline-text-color:  $primary-color;
$button-outline-border-color: $primary-color;

$button-primary-text-color: var(--primaryButtonText,$white-color);
$button-primary-gradient-first: var(--primaryButtonG1,rgba(0,144,209,1));
$button-primary-gradient-second: var(--primaryButtonG2,rgba(0,195,234,1));
$button-primary-background-color: linear-gradient(90deg, $button-primary-gradient-first 27%, $button-primary-gradient-second 79%);


$horizontal-line-color: $gray-color;
$list-item-border-color: $gray-color;

$error-border-color: $alert-color;
$error-icon-color: $alert-color;
$remove-icon-color: $alert-color;

$tooltip-icon-color: $primary-color;
$tooltip-info-background-color: $title-color;
$tooltip-info-text-color: $white-color;
$tooltip-info-font-size: $font-size-12;

$base-font-size: $font-size-14;
$text-font-size: $font-size-16;
$title-font-size: $font-size-26;
$price-header-font-size: $font-size-48;
$subtitle-font-size: $font-size-18;
$expiration-font-size: $font-size-12;
$declaimer-font-size: $font-size-12;
$order-detail-label-font-size: $font-size-18;
$order-detail-value-font-size: $font-size-24;

$control-form-label-font-size: $font-size-14;
$control-form-placeholder-font-size: $font-size-14;
$control-form-text-font-size: $font-size-14;

$button-text-font-size: $font-size-16;
$tab-text-font-size: $font-size-16;
$modal-header-text-font-size: $font-size-24;
$thank-you-header-font-size: $font-size-40;


// depreciated (used in Tab component (not used)
$tab-inactive-background-color: #f2f5f8;
$tab-inactive-text-color: $primary-color;
$tab-active-background-color: $vertical-primary-gradient;
$tab-active-text-color: $white-color;
$tab-bottom-line-color: $primary-color;