@import './normalize';
@import './variables';

.formRow {
  margin-left: -1.2rem;
  margin-right: -1.2rem;
  display: flex;

  .formControl {
    flex: 1;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
}
.inputDate::-webkit-calendar-picker-indicator  {
  filter: invert(1)
}
.formControl {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  ::placeholder {
    color: var(--hintColor)
  }
  label {
    margin-bottom: 1.2rem;
    font-weight: 500;
    font-size: $control-form-label-font-size;
  }

  input,
  .stripeElementContainer {
    height: 4rem;
    padding: 1.2rem 0.9rem;
    border: 0.2rem solid $input-border-color;
    background: $input-background-color;
    font-family: $primary-font-family;
    color: var(--textColor);
    &:hover {
      border-color: $secondary-button-foreground;
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  .otpInputContainer {
    position: relative;
    display: flex;
    flex-direction: column;

    .tooltipMarginRight {
      position: absolute;
      right: -3rem;
      top: calc(50% - 0.7rem);
    }
  }
}

.formCheckboxContainer {
  position: relative;
  padding-right: 2rem;

  .formCheckbox {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 3rem;
    margin-bottom: 2rem;
    font-weight: 500;
    font-size: $control-form-label-font-size;
    cursor: pointer;
    
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkmark {
        border: 0;
        background-image: $vertical-primary-gradient;

        &::after {
          display: block;
        }
      }
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 1.7rem;
      width: 1.7rem;
      border-radius: 0.2rem;
      border: 0.1rem solid $primary-color;

      &:after {
        display: none;

        content: "";
        position: absolute;
        left: 0.6rem;
        top: 0.15rem;
        width: 0.4rem;
        height: 0.8rem;
        border: solid white;
        border-width: 0 0.25rem 0.25rem 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
}
/*
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}
*/
/*
.dateInputContainerBlack {
  position: relative;
  flex: 1;
  display: flex;
  margin: 0 2rem;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 4rem;
    background: url('../images/icon_calendar_black.svg') no-repeat center center;
    background-size: 50%;
  }


  input {
    flex: 1;

  }
}

.dateInputContainerWhite{
  position: relative;
  flex: 1;
  display: flex;
  margin: 0 2rem;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 4rem;
    background: url('../images/icon_calendar_white.svg') no-repeat center center;
    background-size: 50%;
  }


  input {
    flex: 1;

  }
}
*/
// custom styling for input type=date
// custom styling is applied also based on type of browser to achieve homogeneity
.customDateInput {
  position: relative;
  flex: 1;
  display: flex;
  margin: 0 2rem;

  input {
    flex: 1;

  }

  // white - used in all browsers
  &--white {
    @extend .customDateInput;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 4rem;
      background: url('../images/icon_calendar_white.svg') no-repeat center center;
      background-size: 50%;
    }
  }

  // white - for webkit engines only to fix calendar date picker opening
  &--white-webkit {
    @extend .customDateInput;
    ::-webkit-calendar-picker-indicator {
      background: url('../images/icon_calendar_white.svg') no-repeat center center;
    }
  }

  // black - used in all browsers
  &--black {
    @extend .customDateInput;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 4rem;
      background: url('../images/icon_calendar_black.svg') no-repeat center center;
      background-size: 50%;
    }
  }

  // black - for webkit engines only to fix calendar date picker opening
  &--black-webkit {
    @extend .customDateInput;
    ::-webkit-calendar-picker-indicator {
      background: url('../images/icon_calendar_black.svg') no-repeat center center;
    }
  }
}

.tooltipContainer {
  position: absolute;
  right: 0;
  top: 0;
}

.accordionContainer {
  //padding: 0 0.5rem
}

.payMethodForm {
  padding-left: 4rem;
  padding-right: 4rem;
}

.declaimerContainer {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  line-height: 1.4rem;
  font-weight: 400;
  font-size: $declaimer-font-size;
  text-align: justify;
}

.declaimerSecurityContainer {
  display: flex;
  align-items: center;
  padding-right: 4rem;
}

.horizontal {
  flex-direction: row;
  align-items: center;
  margin-bottom: 4rem;

  label {
    margin: 0;
  }
}