@import "./normalize";
@import "./variables";
@import "./form";
@import "./button";

html {
  font-size: 62.5%;
}

body {
  width: 100%;
  height: 100vh;
  background: var(--backgroundMain, $background-main);
  font-family: $primary-font-family;
  font-size: $base-font-size;
  color: $text-color;

  .errorMessage {
    font-family: Cairo;
    font-size: 36px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    text-align: center;
    color: #005075;
  }

  .errorTitle {
    margin: 0 114.5px 23px;
    font-family: Cairo;
    font-size: 41px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.98;
    letter-spacing: normal;
    text-align: center;
    color: #005075;
  }

  // capitalize of first letter requires inline block
  .capitalize {
    display: inline-block;
  }

  .capitalize:first-letter {
    text-transform: uppercase !important;
  }

  .wordwrap {
    word-wrap: break-word;
  }

  .divider {
    margin-bottom: 2.6px;
    border-bottom: 0.1rem solid $input-border-color;
    width: 100%;
    text-align: center;
  }

  .planContent {
    margin-top: 11px;
    margin-bottom: 11px;
  }

  .planScheduleAmount {
    font-weight: 500;
    margin-left: 20px;
  }

  .planInfo {
    margin-bottom: 29px;
  }

  .planAmount {
    font-weight: bold;
    font-size: $font-size-34;
    margin-left: 26px;
    color: var(--amount,$text-color);
  }

  .planDate {
    font-weight: 500;
    font-size: $font-size-18;
  }

  .planWrapper {
    font-size: $text-font-size;
    padding-top: 15px;
  }

  .planCloumnsWrapper {
    display: flex;
  }

  .planLeftColumn {
    width: 50%;
  }

  .planRightColumn {
    width: 50%;
  }

  .planInfoTitle {
    font-size: $subtitle-font-size;
    text-transform: uppercase;
    font-weight: 500;
  }

  @media screen and (max-width: 548px) {
    .planCloumnsWrapper {
      display: block;
    }

    .planLeftColumn {
      width: 100%;
    }

    .planRightColumn {
      width: 100%;
    }

    .content {
      padding: 1.5rem 3.7rem !important;
    }

    #header-space {
        padding: 0 !important;
    }
  }

  // This allow to show paypal pay later button for small screen
  @media screen and (max-width: 375px) {
    .planCloumnsWrapper {
      display: block;
    }

    .planLeftColumn {
      width: 100%;
    }

    .planRightColumn {
      width: 100%;
    }

    .content {
      padding: 1.5rem 1rem !important;
    }

    #header-space {
        padding: 0 !important;
    }
  }

  .bold {
    font-weight: 500;
  }

  .content {
    padding: 3.5rem 7.7rem;
    width: 100%;
    background: var(--backgroundMain,$white-color);

    .title {
      font-size: $title-font-size;
      font-family: $title-font-family;
      font-weight: 600;
      line-height: 1;
      color: $title-color;
      text-transform: uppercase;
      text-align: center;

      &.header {
        font-size: $font-size-32;
        padding-bottom: 0.5rem;
      }

      span {
        display: block;
        margin-bottom: 1rem;
      }
    }

    .subtitle {
      font-size: $subtitle-font-size;
      color: $subtitle-color;
      margin-bottom: 0.7rem;
      margin-top: 0.9rem;
    }

    .info {
      font-size: $text-font-size;
      color: $subtitle-color;
      text-align: center;
    }

    .customAlignedCheckboxLabel {
      text-align: left;
      margin-top: -1px;
      margin-bottom: 50px;
      font-size: 12px;
    }

    .expiration {
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;
      font-size: $expiration-font-size;
      font-weight: 500;
      color: $secondary-color;

      span {
        padding-right: 0.4rem;
      }
    }

    .orderMainDetailsContainer {
      margin-bottom: 2rem;
      font-size: $text-font-size;
    }

    .paymentMethodDetailsContainer {
      padding: 0 2rem 2rem;
      margin-bottom: 2.4rem;
      border-bottom: 1px solid $horizontal-line-color;

      label {
        display: block;
        margin-bottom: 0.5rem;
        font-weight: 500;
        font-size: $text-font-size;
      }
    }

    .linkContainer {
      margin: 0 -1rem 3.6rem -1rem;
      text-align: center;

      .link {
        font-size: $text-font-size;
        color: $link-color;
        cursor: pointer;
        text-align: center;

        &:hover {
          text-decoration: underline;
        }

        &.sm {
          font-size: $base-font-size;
        }
      }
    }

    .noteContainer {
      font-size: $font-size-12;
      font-weight: 500;
      word-wrap: break-word;
    }

    .maxLine2 {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
              line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .mb-0 {
      margin: 0 !important;
    }

    .mb-20 {
      margin-bottom: 2rem !important;
    }

    .mb-24 {
      margin-bottom: 2.4rem !important;
    }

    .mb-27 {
      margin-bottom: 2.7rem !important;
    }

    .mb-28 {
      margin-bottom: 2.8rem !important;
    }

    .mb-36 {
      margin-bottom: 3.6rem !important;
    }

    .mb-48 {
      margin-bottom: 4.8rem !important;
    }

    .mb-60 {
      margin-bottom: 6rem !important;
    }

    .mb-34 {
      margin-bottom: 3.4rem !important;
    }

    .mb-64 {
      margin-bottom: 6.4rem !important;
    }

    .mt-24 {
      margin-top: 2.4rem !important;
    }

    .mt-28 {
      margin-top: 2.8rem !important;
    }

    .ml-8 {
      margin-left: 0.8rem !important;
    }

    .ml-61 {
      margin-left: 6.1rem !important;
    }

    .ml-12 {
      margin-left: 1.4rem !important;
    }

    .bb-0 {
      border-bottom: 0 !important;
    }

    .bt-01 {
      border-top: 0.1rem solid $gray-color;
    }

    .bb-01 {
      border-bottom: 0.1rem solid $gray-color;
    }
  }

  .Non-conosci-TS-Pay {
    height: 14px;
    margin-top: 12px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    white-space: nowrap;
    letter-spacing: normal;
    text-align: center;
    color: #005075;
  }


  .Tspay-Informational {
    height: 14px;
    margin-bottom: 40px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    white-space: nowrap;
    letter-spacing: normal;
    text-align: right;
    color: black;
  }

  .Tspay-Informational .text-style-1 {
    font-weight: 500;
    font-style: normal;
  }

  .Non-conosci-TS-Pay .text-style-1 {
    font-weight: 500;
    font-style: normal;
  }
}

.MuiBox-root {
  padding: 40px 0 0 0 !important;
}