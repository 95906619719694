@import './normalize';
@import './variables';

.btn {
  width: 100%;
  height: 4.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $button-text-font-size;
  border-radius: 0.2rem;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;

  &.btn-primary {
    color: $button-primary-text-color;
    background: $button-primary-background-color;
    text-transform: uppercase;
  }

  &.btn-secondary {
    color: $secondary-button-foreground;
    background: $input-background-color;
  }

  &.btn-outline {
    color: $button-outline-text-color;
    border: 0.2rem solid $button-outline-border-color;
    background: transparent;
  }

  &.btn-alert {
    color: $white-color;
    background: $alert-color;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
