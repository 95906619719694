@import "../../../assets/styles/variables";

.icon {
  position: relative;
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  color: $tooltip-icon-color;
}

.schedulePlanInfo {
  display: flex;
  align-items: center;
}

.chargeDateSpacing {
  margin-left: 1.5rem;
}